import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import AuthContext from "../context/authProvider"
import { authService } from "../services/authService"
import { useQueryClient } from "@tanstack/react-query"

const emptyUser = { id: 0 }

export default function PrivateRoutes() {
  const { auth, setAuth } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const decodedToken = auth.token ? JSON.parse(atob(auth.token.split(".")[1])) : null
  const expirationTime = decodedToken ? decodedToken.exp * 1000 : null

  if (!auth.token || !expirationTime || Date.now() >= expirationTime) {
    authService.logout()
    queryClient.clear()
    if (setAuth) {
      setAuth({ token: null, user: emptyUser })
    }
    return <Navigate to={"/login"} replace />
  }

  if (!auth.user || !auth.user.id) {
    if (setAuth) {
      setAuth({ token: null, user: emptyUser })
    }
    return <Navigate to={"/login"} replace />
  }

  return <Outlet />
}