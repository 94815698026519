import axios from "axios"
import { showErroPage, sortByAttribute } from "../utils/helper"

class UserService {

  private baseUrl = process.env.REACT_APP_API_URL + "/user";


  public createAdmin = async (userInfo: any, imageFile: File | null = null) => {
    if (!userInfo) throw new Error("No user info provided")
    try {
      const response = await axios.post(
        `${this.baseUrl}/admin`,
        { userInfo }
      );
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }
  public createOwner = async (userInfo: any, imageFile: File | null = null) => {
    if (!userInfo) throw new Error("No user info provided")
    try {
      const response = await axios.post(
        `${this.baseUrl}/owner`,
        { userInfo },
      );
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public createCompanyManager = async (userInfo: any, imageFile: File | null = null) => {
    try {
      const response = await axios.post(
        `${this.baseUrl}/company-manager`,
        { userInfo },
      );
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public createAreaManager = async (userInfo: any, organizationIds: number[], imageFile: File | null = null) => {
    try {
      const response = await axios.post(
        `${this.baseUrl}/area-manager`,
        { userInfo, organizationIds },
      );
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);

      }
    }
  }

  public createManager = async (userInfo: any, locationIds: number[], imageFile: File | null = null) => {
    try {
      const response = await axios.post(
        `${this.baseUrl}/manager`,
        { userInfo, locationIds },
      );
      // wait for image to upload before returning
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public createAssistantManager = async (userInfo: any, locationIds: number[], imageFile: File | null = null) => {
    try {

      const response = await axios.post(
        `${this.baseUrl}/assistant-manager`,
        { userInfo, locationIds },
      );
      // wait for image to upload before returning
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public uploadProfileImage = async (imageFile: File, userId: number, firstName: string, lastName: string) => {
    if (!imageFile) return;
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/update/profile-image/${userId}/${firstName}-${lastName}`,
      )
      if (imageFile.type !== "image/jpeg" && imageFile.type !== "image/png" && imageFile.type !== "image/jpg") throw new Error("Invalid file type")
      const response = await axios.put(
        signedUrl,
        imageFile,
        {
          headers: {
            "Content-Type": imageFile.type,
            "Authorization": undefined,
          }
        }
      );
      return response.data;
    } catch (error) {
      return error as Error;
    }
  };

  public getViewableProfileImage = async (userId: number, firstName: string, lastName: string) => {
    try {
      const { data: signedUrl } = await axios.get(
        `${this.baseUrl}/profile-image/${userId}/${firstName}-${lastName}`, {
        timeout: 5000
      }
      )
      return signedUrl;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }

  public getOne = async (userId: number) => {
    // Don't make the request if there's no token
    const token = localStorage.getItem("token")
    if (!token) {
      throw new Error("No token available")
    }

    try {
      const response = await axios.get(
        `${this.baseUrl}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        if (error.response?.status === 401) {
          localStorage.removeItem("token")
          throw new Error("Invalid token")
        }
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
        throw error
      }
    }
  }

  public getAllByUser = async (userId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-user/${userId}`
      )
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }
  public getSomeByUser = async (userId: number, limit: number, page: number, searchTerm?: string) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-user/${userId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      )
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }

  public getAllByLocation = async (locationId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-location/${locationId}`
      )
      sortByAttribute(response.data, "lastName")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }
  public getSomeByLocation = async (locationId: number, limit: number, page: number, searchTerm?: string) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-location/${locationId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      )
      // sortByAttribute(response.data, "lastName")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }

  public getAllByOrganization = async (organizationId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/all/by-organization/${organizationId}`
      )
      sortByAttribute(response.data, "lastName")
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }
  public getSomeByOrganization = async (organizationId: number, limit: number, page: number, searchTerm?: string) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/some/by-organization/${organizationId}?limit=${limit}&offset=${page}&searchTerm=${searchTerm || ""}`
      )
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
        throw new Error("axios error")
      } else {
        console.error(error)
        showErroPage()
      }
    }
  }

  public updateTableOrder = async (tableId: number, tableName: string, displayOrder: number, id?: number) => {
    try {
      if (!id) {
        const response = await axios.post(`${this.baseUrl}/create/table-order`, {
          tableName: tableName,
          tableId: tableId,
          displayOrder: displayOrder
        });
        return response.data
      }
      const response = await axios.put(`${this.baseUrl}/update/table-order/${id}`, {
        tableName: tableName,
        tableId: tableId,
        displayOrder: displayOrder
      })
      return response.data
    } catch (error) {
      console.error("Failed to update order preferences:", error);
    }
  };

  public update = async (userInfo: any, organizationIds: number[] = [], locationIds: number[] = [], imageFile: File | null,) => {
    try {
      const response = await axios.put(
        `${this.baseUrl}/update/user/${userInfo.id}`,
        // updatedUserInfo,
        { userInfo, organizationIds, locationIds },
      )
      if (imageFile) await this.uploadProfileImage(imageFile, response.data.id, response.data.firstName, response.data.lastName)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }


  public delete = async (id: number) => {
    try {
      const response = await axios.delete(
        `${this.baseUrl}/${id}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        console.error(error);
      }
    }
  }
}

export const userService = new UserService()