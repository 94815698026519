import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { managementCompanyService } from '../../services/managementCompanyService'
import Table from './common/Table'
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import { OWNER } from '../../utils/roles'
import ConfirmationModal from '../modals/ConfirmationModal'
import { ManagementCompany } from '../../Interfaces'
import { CgSpinner } from 'react-icons/cg'

export default function ManagementCompaniesTable() {
  const { id, roleId } = useUser()
  const [searchTerm, setSearchTerm] = useState("")
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<ManagementCompany | null>(null)
  const [deleteStatus, setDeleteStatus] = useState<string | null>(null)
  const navigate = useNavigate()

  const { data: companies = [], refetch, isLoading, error } = useQuery<ManagementCompany[], Error>(
    ['managementCompanies', id],
    () => managementCompanyService.getAll(id),
    {
      enabled: roleId && roleId >= OWNER ? true : false,
      retry: 2
    }
  )

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Website", key: "website" }
  ]

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const handleCompanyClick = async (company: ManagementCompany) => {
    try {
      // Fetch the company details using getOne to ensure we have the latest data
      const companyDetails = await managementCompanyService.getOne(company.id)
      navigate(`/app/management-companies/${company.id}`, {
        state: { currentCompany: companyDetails }
      })
    } catch (error) {
      console.error("Error fetching company details:", error)
      // Navigate with the data we have if the fetch fails
      navigate(`/app/management-companies/${company.id}`, {
        state: { currentCompany: company }
      })
    }
  }

  const handleDelete = async () => {
    if (!selectedCompany) return
    setDeleteStatus("Deleting...")
    try {
      await managementCompanyService.delete(selectedCompany.id)
      setDeleteStatus("Deleted!")
      setTimeout(() => {
        setDeleteStatus(null)
        setShowDeleteModal(false)
        setSelectedCompany(null)
        refetch()
      }, 1500)
    } catch (error) {
      setDeleteStatus("Error")
      setTimeout(() => {
        setDeleteStatus(null)
      }, 1500)
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <CgSpinner className="animate-spin text-4xl text-primary" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="w-full p-4 text-center text-red-600">
        Error loading management companies. Please try again later.
      </div>
    )
  }

  const filteredCompanies = companies.filter(company => {
    const searchRegex = new RegExp(searchTerm, 'i')
    return (
      searchRegex.test(company.name) ||
      searchRegex.test(company.email || '') ||
      searchRegex.test(company.phone || '') ||
      searchRegex.test(company.website || '')
    )
  })

  return (
    <>
      <Table
        data={filteredCompanies}
        title="Management Companies"
        handleSearch={handleSearch}
        headers={headers}
        to="/app/management-companies/add"
        onRowClick={handleCompanyClick}
      />
      <ConfirmationModal
        isOpen={showDeleteModal}
        title={`Delete ${selectedCompany?.name}?`}
        message="This will permanently delete the management company and cannot be undone."
        saveStatus={deleteStatus}
        onConfirm={handleDelete}
        onCancel={() => {
          setShowDeleteModal(false)
          setSelectedCompany(null)
        }}
      />
    </>
  )
} 