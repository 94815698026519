import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Children } from "../../Interfaces"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { Suspense, useContext, useEffect } from "react"
import { navigation } from "../../utils/helper"
import LoadingSpinTrail from "../../components/loading/LoadingSpinTrail"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../pages/errors/ErrorFallback"
import DataContext from "../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import useUser from "../../hooks/useUser"
import { userService } from "../../services/userService"
import Footer from "../../components/footer"
import { authService } from "../../services/authService"

export default function MainLayout() {
  navigation.navigate = useNavigate()
  navigation.location = useLocation()
  const navigate = useNavigate()
  const user = useUser()
  const { setCurrentUser } = useContext(DataContext)

  // Redirect to login if no token or invalid user
  useEffect(() => {
    if (!localStorage.getItem("token") || user.id === 0) {
      authService.logout()
      navigate("/login", { replace: true })
    }
  }, [user.id, navigate])

  const { data, error } = useQuery(
    ["user", user.id],
    () => userService.getOne(user.id),
    {
      cacheTime: 99999,
      staleTime: 99999,
      enabled: user.id > 0 && !!localStorage.getItem("token"),
      retry: false,
      onError: (error: Error) => {
        if (error.message === "Invalid token" || error.message === "No token available") {
          authService.logout()
          navigate("/login", { replace: true })
        }
      }
    }
  )

  useEffect(() => {
    if (data) {
      setCurrentUser(data)
    }
  }, [data, setCurrentUser])

  // Only show loading if we're waiting for valid user data
  if (!data && user.id > 0 && !error && !!localStorage.getItem("token")) {
    return <LoadingSpinTrail />
  }

  return (
    <div className="flex w-full max-h-full">
      <Navbar />
      <Sidebar />
      <div className="flex w-full justify-center z-0 mt-12 bg-secondary-light max-h-screen overflow-y-auto">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinTrail />}>
            <div className="flex flex-col items-center w-full md:p-6 justify-between" style={{}}>
              <Outlet />
              <Footer />
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  )
}
