import { useEffect, useState } from "react"
import { FieldState, ManagementCompany } from "../../Interfaces"
import Header from "../../components/forms/Header"
import { addManagementCompanyFields } from "../../constants/formFields"
import Input from "../../components/forms/Input"
import ImageDragDrop from "../../components/forms/ImageDragDrop"
import { managementCompanyService } from "../../services/managementCompanyService"
import { cleanPhoneNumber } from "../../utils/helper"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import SaveButtonWithStatus from "../../components/general/SaveButtonWithStatus"
import ConfirmationModal from "../../components/modals/ConfirmationModal"
import { useQuery } from "@tanstack/react-query"
import { CgSpinner } from "react-icons/cg"

const fields = addManagementCompanyFields
let fieldsState: FieldState = {}
fields.forEach((field) => (fieldsState[field.id] = ""))

// Map field IDs to API field names
const fieldToApiMap: { [key: string]: string } = {
  companyName: "name",
  email: "email",
  phone: "phone",
  website: "website"
}

// Function to format phone number as user types
const formatPhoneNumber = (value: string) => {
  // Remove all non-numeric characters
  const phoneNumber = value.replace(/\D/g, '')

  // Format based on length
  if (phoneNumber.length <= 3) {
    return phoneNumber
  } else if (phoneNumber.length <= 6) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
  } else if (phoneNumber.length <= 10) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
  } else {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }
}

export default function ManagementCompanyForm() {
  const { id } = useParams()
  const location = useLocation()
  const currentCompany = location.state?.currentCompany as ManagementCompany
  const [formState, setFormState] = useState(fieldsState)
  const [companyLogo, setCompanyLogo] = useState<File | null>(null)
  const [saveStatus, setSaveStatus] = useState<string | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState<string | null>(null)
  const navigate = useNavigate()

  // Fetch company data if we have an ID but no currentCompany
  const { data: fetchedCompany } = useQuery<ManagementCompany>(
    ["managementCompany", id],
    () => managementCompanyService.getOne(Number(id)),
    {
      enabled: !!id && !currentCompany,
    }
  )

  const company = currentCompany || fetchedCompany

  // Fetch logo URL using React Query
  const { data: logoUrl, isLoading: isLogoLoading, isError: isLogoError } = useQuery(
    ["managementCompanyLogo", company?.id],
    () => managementCompanyService.getViewableLogo(company!.id, company!.name),
    {
      enabled: !!company?.id && !!company?.name,
    }
  )

  useEffect(() => {
    if (company) {
      console.log("Setting form state with company:", company)
      setFormState({
        companyName: company.name || "",
        email: company.email || "",
        phone: company.phone ? formatPhoneNumber(company.phone) : "",
        website: company.website || ""
      })
    }
  }, [company])

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    if (e.target.name === "phone") {
      // Allow any input but format it as they type
      const formattedPhone = formatPhoneNumber(e.target.value)
      setFormState({ ...formState, [e.target.name]: formattedPhone })
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSaveStatus("Saving...")

    // Convert form state fields to API fields
    const companyInfo = Object.keys(formState).reduce((acc, key) => {
      const apiField = fieldToApiMap[key]
      if (apiField) {
        acc[apiField] = formState[key]
      }
      return acc
    }, {} as any)

    // Clean phone number if present
    if (companyInfo.phone) {
      companyInfo.phone = cleanPhoneNumber(companyInfo.phone)
    }

    try {
      let managementCompany
      if (currentCompany) {
        managementCompany = await managementCompanyService.update({ ...companyInfo, id: currentCompany.id }, companyLogo)
      } else {
        managementCompany = await managementCompanyService.create(companyInfo, companyLogo)
      }

      if (managementCompany) {
        setSaveStatus("Saved!")
        setTimeout(() => {
          setSaveStatus(null)
          setFormState(fieldsState)
          navigate("/app/management-companies")
        }, 1000)
      } else {
        setSaveStatus("Error")
        setTimeout(() => {
          setSaveStatus(null)
        }, 1500)
      }
    } catch (error) {
      setSaveStatus("Error")
      setTimeout(() => {
        setSaveStatus(null)
      }, 1500)
    }
  }

  const handleDelete = async () => {
    if (!currentCompany) return
    setDeleteStatus("Deleting...")
    try {
      await managementCompanyService.delete(currentCompany.id)
      setDeleteStatus("Deleted!")
      setTimeout(() => {
        setDeleteStatus(null)
        setShowDeleteModal(false)
        navigate("/app/management-companies")
      }, 1500)
    } catch (error) {
      setDeleteStatus("Error")
      setTimeout(() => {
        setDeleteStatus(null)
      }, 1500)
    }
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <section className="p-5 w-full lg:max-w-4xl md:p-5 md:shadow-xl md:rounded-xl bg-white max-h-screen overflow-auto">
        <Header
          heading={currentCompany ? "Edit Management Company" : "Add Management Company"}
          paragraph="Management companies are required to add areas, locations, and users."
        />
        <form className="mt-8 space-y-2" onSubmit={handleSubmit}>
          <div className="lg:flex lg:justify-evenly items-center">
            <section className="lg:w-full">
              <div>
                {fields.map((field) => (
                  <Input
                    className="bg-gray-50 border-gray-300"
                    key={field.id}
                    handleChange={handleInputChange}
                    value={formState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.id}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                    pattern={field.pattern}
                  />
                ))}
              </div>
              <div className="w-full"></div>
            </section>
            <section className="lg:w-full lg:pl-5 lg:m-auto">
              <ImageDragDrop
                setImageState={setCompanyLogo}
                title="Upload Logo"
              />
            </section>
          </div>
          <div className="flex justify-between">
            <SaveButtonWithStatus saveStatus={saveStatus} handleSave={handleSubmit} isForm={true} className="w-1/3" />
            {currentCompany && (
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="w-1/3 bg-red-500 text-white rounded-lg py-2 px-4 hover:bg-red-600"
              >
                Delete Company
              </button>
            )}
          </div>
        </form>
      </section>
      <ConfirmationModal
        isOpen={showDeleteModal}
        title={`Delete ${currentCompany?.name}?`}
        message="This will permanently delete the management company and cannot be undone."
        saveStatus={deleteStatus}
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  )
}
