import { useContext } from "react"
import AuthContext from "../context/authProvider"
import { User } from "../Interfaces"

const emptyUser = { id: 0 } as User

export default function useUser() {
  const { auth } = useContext(AuthContext)

  // Return empty user if no token or no user data
  if (!auth.token || !auth.user) {
    return emptyUser
  }

  const user = auth.user
  // Return empty user if the user data is invalid
  if (!user.id || user.id === 0) {
    return emptyUser
  }

  return user
}