import axios from "axios"
import { createContext, useEffect, useState } from "react"
import { Children, UserAuth, User } from "../Interfaces"

const emptyUser = { id: 0 } as User

interface AuthContext {
  auth: UserAuth
  setAuth?: (auth: UserAuth) => void
}

const AuthContext = createContext<AuthContext>({
  auth: {
    token: localStorage.getItem("token"),
    user: emptyUser
  }
})

export const AuthProvider = ({ children }: Children) => {
  const [auth, setAuth] = useState<UserAuth>(() => {
    const token = localStorage.getItem("token")
    const storedUser = localStorage.getItem("user")
    let user = emptyUser

    try {
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser)
        // Only use stored user if it has an id
        if (parsedUser && parsedUser.id) {
          user = parsedUser
        }
      }
    } catch (e) {
      console.error("Failed to parse stored user:", e)
    }

    return { token, user }
  })

  useEffect(() => {
    if (auth?.token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + auth.token
    } else {
      delete axios.defaults.headers.common["Authorization"]
      localStorage.removeItem("token")
      localStorage.removeItem("user")
    }
  }, [auth?.token])

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}

export default AuthContext
