import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/home"
import LoginPage from "./pages/login"
import Dashboard from "./pages/dashboard"
import Locations from "./pages/locations"
import PrivateRoutes from "./guards/PrivateRoutes"
import ResetPasswordPage from "./pages/resetPassword"
import MainLayout from "./layouts/mainLayout"
// import AddOwner from "./pages/addOwner"
import AddManagementCompany from "./pages/addManagementCompany"
import AddLocation from "./pages/addLocation"
import LoadingSpinTrail from "./components/loading/LoadingSpinTrail"
import UserProfile from "./pages/userProfile"
import SingleLocation from "./pages/locations/location"
import AddOrganization from "./pages/addOrganization"
import SingleOrganization from "./pages/organizations/organization"
import UpdateProfile from "./pages/userProfile/updateProfile"
import UpdateOrganization from "./pages/organizations/organization/updateOrganization"
import Organizations from "./pages/organizations"
import Users from "./pages/users"
import CreatWalks from "./pages/locations/location/createWalks"
import SingleWalkReport from "./pages/locations/location/walkReport"
import TermsOfServicePage from "./pages/legal/termsOfService"
import PrivacyPolicyPage from "./pages/legal/privacyPolicy"
import ResetEmailSentPage from "./pages/resetPassword/emailSent"
import ConfirmNewPasswordPage from "./pages/resetPassword/confirmNewPassword"
import UpdateLocation from "./pages/locations/location/updateLocation"
import AddUser from "./pages/addUser"
import ManagementCompaniesTable from "./components/tables/ManagementCompaniesTable"

function App() {
  return (
    <main className="min-h-full h-screen flex justify-center">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/app" element={<MainLayout />}>
              <Route path="/app" element={<Dashboard />} />
              <Route path="/app/users" element={<Users />} />
              <Route path="/app/users/:id" element={<UserProfile />} />
              <Route path="/app/users/update/:id" element={<UpdateProfile />} />
              <Route path="/app/locations" element={<Locations />} />
              <Route path="/app/locations/:id/create-walks" element={<CreatWalks />} />
              <Route path="/app/locations/:id/walk-report/:walkReportId" element={<SingleWalkReport />} />
              <Route path="/app/locations/:id" element={<SingleLocation />} />
              <Route path="/app/locations/update/:id" element={<UpdateLocation />} />
              <Route path="/app/organizations" element={<Organizations />} />
              <Route path="/app/organizations/:id" element={<SingleOrganization />} />
              <Route path="/app/organizations/update/:id" element={<UpdateOrganization />} />
              <Route path="/app/addLocation" element={<AddLocation />} />
              <Route path="/app/addOrganization" element={<AddOrganization />} />
              <Route path="/app/addUser" element={<AddUser />} />
              <Route path="/app/management-companies" element={<ManagementCompaniesTable />} />
              <Route path="/app/management-companies/add" element={<AddManagementCompany />} />
              <Route path="/app/management-companies/:id" element={<AddManagementCompany />} />
              <Route path="/app/loading" element={<LoadingSpinTrail />} />
            </Route>
          </Route>
          <Route path="terms-of-service" element={<TermsOfServicePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="resetPassword" element={<ResetPasswordPage />} />
          <Route path="resetPassword/emailSent" element={<ResetEmailSentPage />} />
          <Route path="reset-passsword/confirm-new-password/:token" element={<ConfirmNewPasswordPage />} />
          <Route path="/" element={<Home />}></Route>
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="/signup" element={<SignupPage/>} /> */}
          <Route
            path="error"
            element={
              <span className="flex h-full items-center">
                <h1>An Error has occured</h1>
              </span>
            }
          />
          <Route
            path="*"
            element={
              <span className="flex h-full items-center">
                <h1>404 Page Not Found</h1>
              </span>
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  )
}

export default App
